import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import {MiniChart, ForexCrossRates } from "react-ts-tradingview-widgets";
import Markets from '../Home/Markets';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

const MarketsAccount = () => {
  const [coinSymbol, setCoinSymbol] = useState('BINANCE:BTCUSDT');
  const appMode = useSelector((state) => state.mode.mode);

  return (
    <div style={{marginTop: '75px'}}>
      <Card sx={{height: "480px", marginTop: '65px', borderRadius: '15px', overflow: 'hidden'}}>
        <CardContent>
            <ForexCrossRates 
                locale= "en"
                colorTheme= {appMode}
                isTransparent= {true}
                width="100%"/>
                 {appMode === 'dark'? 
                          <Box sx={{height: '20px', backgroundColor: '#1e1e1e', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
                          
                          </Box>
                          :
                          <Box sx={{height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
                          
                          </Box>
                          }
        </CardContent>
      </Card>
    </div>
  )
}

export default MarketsAccount
