import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthStatus, setToken, setPendingEmail } from '../../request/Auth/authSlice';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Currency } from '@depay/local-currency';


import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const SecuritySettings = () => {
    const user = useSelector((state) => state.user.user);

    const dispatch = useDispatch();
    const initialLogin = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        address: '',
        referral: '',
        country: ''

    }
   
    const [error, setErros] = useState('');
    
    const [registerForm, setRegisterForm] = useState(initialLogin);
    const appMode = useSelector((state) => state.mode.mode);
    const redirectTo = useSelector((state) => state.redirect.redirect);
    const inviter = useSelector((state) => state.referrals.inviter);

    const navigate = useNavigate();

    const onChange = (e) =>
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
    let currency = Currency.getCode();

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/security-settings`, {
           
            old_password: registerForm.password,
            new_password: registerForm.password_confirmation,
           
          });
        },
        {
          onSuccess: (res) => {
              localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
              const event2 = new Event('processed');
              window.dispatchEvent(event2);
              const event = new Event('newMessage');
              window.dispatchEvent(event);

          },
          onError: (err) => {   
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)     
          const event2 = new Event('processed');
          window.dispatchEvent(event2);          
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
              const event2 = new Event('processed');
          window.dispatchEvent(event2);
          }    
          },
        }
      );

  return (
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
            <CardContent>
            <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                Security Settings
            </Typography>
            <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                Fill the form below to update account setings
            </Typography>
            

                <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    {(error !== '' && error.email) ?

                    <OutlinedInput
                    onChange={onChange}
                    error
                    readOnly={true}
                    id="email"
                    type={'email'}
                    value={user.email}
                    name="email"
                    label="Email"
                    helperText={error.email}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="email"
                    readOnly={true}
                    value={user.email}
                    type={'email'}
                    name="email"
                    label="Email"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.email}
                    </Typography>
                </FormControl>

                <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                    <InputLabel htmlFor="password">Old Password</InputLabel>
                    <OutlinedInput
                    onChange={onChange}
                    defaultValue={user.password}
                    id="password"
                    name="password"
                    type={'password'}
                    label="Old Password"
                    />
                </FormControl>

                <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                    <InputLabel htmlFor="password_confirmation">New Password</InputLabel>
                    {(error !== '' && error.password) ? 
                    <OutlinedInput
                    error
                    onChange={onChange}
                    id="password_confirmation"
                    name="password_confirmation"
                    type={'password'}
                    label="New Password"
                    helperText={error.password}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="password_confirmation"
                    name="password_confirmation"

                    type={'password'}
                    label="New Password"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.password}
                    </Typography>
                </FormControl>


            </CardContent>                    
            <CardActions>
                <Button onClick={postLogin} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Saving...' : 'Save'}</Button>
            </CardActions>
        </Card>
  )
}

export default SecuritySettings
