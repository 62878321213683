import React, {useState, useEffect} from 'react'
import CoinMarket from './CoinMarket';
import WalletCards from '../Wallet/WalletCards';
// import MobileMenu from './MobileMenu';
import { TickerTape } from "react-ts-tradingview-widgets";
import { Card, CardContent, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';


const Index = () => {
  const appMode = useSelector((state) => state.mode.mode);
  const user = useSelector((state) => state.user.user);
  const [ip, setIP] = useState("192.168.23.5");

  const getData = async () => {
    fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => setIP(data.ip))
    .catch(error => console.log(error))
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Card sx={{marginTop: '75px', marginBottom: '-65px'}}>
      <TickerTape 
      colorTheme={appMode}
      displayMode="adaptive"
      isTransparent={true}
      ></TickerTape>
      {appMode === 'dark'? 
            <Box sx={{display: 'flex', justifyContent: 'space-between', paddingY: '10px', paddingX: '18px', height: '20px', backgroundColor: '#1e1e1e', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
            <Typography variant="body2">WELCOME {user.name}</Typography>
            <Typography variant="body2">
              Login Ip: {ip}
            </Typography>
            </Box>
            :
            <Box sx={{display: 'flex', justifyContent: 'space-between', paddingY: '10px', paddingX: '18px', height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
            <Typography variant="body2">WELCOME {user.name}</Typography>
            <Typography variant="body2">
              Login Ip: {ip}
            </Typography>
            </Box>
            }
    </Card>    
        
    <WalletCards/>
    <CoinMarket/>
    </>
  )
}

export default Index
