import React, {Fragment, useState, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WalletSlide from '../Wallet/WalletSlide';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Currency } from '@depay/local-currency';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import WalletButton from '../Wallet/WalletButton';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

const Investment = () => {
    const user = useSelector((state) => state.user.user);
    const investments = useSelector((state) => state.history.investments);
    const [rate, setRate] = useState(1);

    const callRate = async()=> {
      await Currency.fromUSD({ amount: 1 }).then(amount=>{
        setRate(amount.amount);
       });
    
  };
  const getDate = (date) => new Date(date).toLocaleString('en-GB', {
    hour12: false,
  });

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    useEffect(() => {
      callRate();
    }, []);

  return (
    <div style={{marginTop: '75px'}}>

{/* <WalletSlide/> */}
      {/* <Card >
        <CardActions sx={{justifyContent: 'space-between'}}>

        <WalletButton/>        

        <WithdrawalButton/>

        </CardActions>
      </Card> */}

<Typography mt={4} px={3} sx={{ marginTop: '10px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        Your Copying History
</Typography>


{investments.length > 0 &&
<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

{investments.map(investment => (
   <Fragment key={investment.id}>
  <Link  to={`/view-inestment/${investment.id}`} component={RouterLink} underline="none" color="inherit">

  <ListItem alignItems="flex-start">
  <ListItemAvatar>
    
    {investment.status === 'pending' &&
      <Avatar  sx={{ bgcolor: '#f7a21d' }}>
       <PendingIcon/>
      </Avatar>
    }
       
    {investment.status === 'approved' &&
     <Avatar  sx={{ bgcolor: '#37dc8a' }}>
       <CheckCircleIcon/>
      </Avatar>
     }

    {investment.status === 'declined' &&
    <Avatar  sx={{ bgcolor: '#dd0a22' }}>
      <DangerousIcon/>
    </Avatar>
     
     }

    {investment.status === 'completed' &&
    <Avatar  sx={{ bgcolor: '#ccc6c6' }}>
      <DoneAllIcon/>
    </Avatar>
     
     }
    
     
  </ListItemAvatar>

  <ListItemText
    primary="Amount"
    secondary={
      <React.Fragment>
        <Typography
          sx={{ display: 'inline' }}
          component="span"
          variant="body2"
          color="text.primary"
        >
          {formatPrice(investment.amount)}
          <span style={{color: 'gray', fontSize: '8px', marginLeft: '5px'}}>{getDate(investment.created_at)}</span>
        </Typography>
      </React.Fragment>
    }
  />   

      <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'center', alignItems: 'right'}}>
        <Typography mt={1} edge="end">
        Profit
        </Typography>
        <Typography sx={{fontWeight: 'bolder'}} color={parseFloat(investment.profit) > 0 ? 'primary.success': 'error'}  edge="end">
        {formatPrice(investment.profit)}
        </Typography>
      </Box>  
</ListItem>
</Link>
<Divider  component="li" variant="inset" />

</Fragment>
))}
</List>
}
    </div>
  )
}

export default Investment
