import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    coins: JSON.parse(localStorage.getItem('coins')) || {},
    addresses: JSON.parse(localStorage.getItem('addresses')) || [],
    coinRates: JSON.parse(localStorage.getItem('coin_rate')) || [],
    forex: JSON.parse(localStorage.getItem('forex')) || [],
    stock: JSON.parse(localStorage.getItem('stock')) || [],

}

export const coinSlice = createSlice({
    name: 'coins',
    initialState,

    reducers: {
        setCoins: (state, action) =>{
            state.coins = action.payload;  
            localStorage.setItem('coins', JSON.stringify(state.coins));
        },
        setAddresses: (state, action) =>{
            state.addresses = action.payload;  
            localStorage.setItem('addresses', JSON.stringify(state.addresses));
        },

        setForex: (state, action) =>{
            state.forex = action.payload;  
            localStorage.setItem('forex', JSON.stringify(state.forex));
        },

        setStock: (state, action) =>{
            state.stock = action.payload;  
            localStorage.setItem('stock', JSON.stringify(state.stock));
        },

        setRate: (state, action) =>{
            state.coinRates = action.payload;  
            localStorage.setItem('coin_rate', JSON.stringify(state.coinRates));
        },
    }
})

export const {setCoins, setRate, setForex, setStock, setAddresses} = coinSlice.actions;
export default coinSlice.reducer;