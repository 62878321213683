import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';
import apiClient from "../../request/http-common";
import { useDispatch } from 'react-redux';
import { setTradings } from '../../request/Analysis/hitorySlice';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Chip from '@mui/material/Chip';
import { useLocation } from 'react-router-dom';

const TradeStock = () => {
    const location = useLocation();
    const {pathname} = location;
    const [error, setErros] = useState('');
    const coinRates = useSelector((state) => state.coins.coinRates);

    const appMode = useSelector((state) => state.mode.mode);
    const currentPair = useSelector((state) => state.currentPair.currentPair);
    const tradingPlans = useSelector((state) => state.plans.tradingPlans);

    
    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);

    const fundings = useSelector((state) => state.wallet.fundings);
  const tradings = useSelector((state) => state.history.tradings);
  
  const calculateBalance = (coin) => {
    let amount = 0;
    fundings.forEach(fund => {
      if (fund.gateway === coin) {
        amount += parseFloat(fund.amount);
      }
      });

      tradings.forEach(trade => {
        if (trade.pair === coin) {
          amount += parseFloat(trade.profit);
        }
        });

    
      return amount;
  }

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);


    const initwallet = {
        start_time : '',
        duration : '',
        stake : '', 
        stop_loss: '',
        take_profit: '',
        source: '',
    }

    const [walletForm, setwalletForm] = useState(initwallet);
    const onChange = (e) =>
    setwalletForm({ ...walletForm, [e.target.name]: e.target.value });
  

    const [amount, setAmount] = useState(0);  
    const navigate = useNavigate();
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
    const dispatch = useDispatch();

    const [selectedPlan, setSelectedPlan] = useState('');
    const [source, setSource] = useState('');

    const setPlanDetails = (planId) =>{
      tradingPlans.forEach(plan => {
        if (parseInt(plan.id) === parseInt(planId)) {
          setSelectedPlan(plan);
          setSource(planId);
        }
      });
    }

    const [orderType, setOrderType] = useState('up');

    const { isLoading: isInvesting, mutate: postInvestment } = useMutation(
      async () => {
        return await apiClient.post(`/api/start-trading`, {
          timer_units: source,
          exch: currentPair,
          amount: amount, 
          duration: walletForm.duration,
          stop_loss: walletForm.stop_loss,
          take_profit: walletForm.take_profit,
          trading_pair: currentPair,
          order_type: orderType,
          pair: currentPair,
          payment_source: walletForm.source,
          entry_point: 0
        });
      },
      {
        onSuccess: (res) => {
            if (res.data.status === 'success') {
              dispatch(setTradings(res.data.tradings));
              localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
              setTimeout(() => {
                navigate('/trade-history');
              }, 2000);
            }            
        },
        onError: (err) => {
              if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
            setErros(myerror.errors)               
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
            }
        },
      }
    );


  return (
    <div style={{marginTop: '70px'}}>
      <Container>
            <Typography mb={2}  ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textTransform: 'uppercase', textAlign: 'center'  }} gutterBottom variant="h6" component="div">
              <Chip icon={<SwapHorizIcon/>} label={`${currentPair}`} variant="outlined" />
            </Typography>
            {currentPair !== null && 
            <Grid container  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                <Grid item xs={12} md={8}>
                    <Card px={0}  sx={{borderRadius: '9px', height: '100%'}}>
                        <CardContent justifyContent="center" sx={{padding: 0, marginBottom: '0px'}}>

                          {/* <TradingChart pair={`${currentPair}USD`}/> */}

                        <AdvancedRealTimeChart
                                symbol= {currentPair}
                                interval= "1"
                                timezone= "Etc/UTC"
                                theme= {appMode}
                                locale= "en"
                                style='1'
                                hide_top_toolbar= {true}
                                enable_publishing= {false}
                                save_image={false}
                                backgroundColor={appMode === 'dark' ? '#1e1e1e' : '#ffffff'}
                                hide_side_toolbar={true}
                                allow_symbol_change={false}
                                container_id= "898980"
                                isTransparent = {true}
                                range='1M'
                                height={400}
                                width="100%"
                              >
                               </AdvancedRealTimeChart>
                          {appMode === 'dark'? 
                          <Box sx={{height: '20px', backgroundColor: '#1e1e1e', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
                          
                          </Box>
                          :
                          <Box sx={{height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
                          
                          </Box>
                          }
                          <Avatar sx={{display: 'absolute', zIndex: 10, transform: 'translate(10px, -170px)'}} alt="Payment icon" src="https://res.cloudinary.com/dg8pxsdme/image/upload/v1705500463/trustutilsdefi500_zqpbh7.png" />
                          
                          </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sx={{display: {md: 'block', xs: 'none'}}} md={4}>
                    <Card px={2}  sx={{borderRadius: '9px', height: '100%'}}>
                      
                        <CardContent justifyContent="center">
                          
                        <Box mt={4} sx={{display: 'flex', justifyContent: 'space-between'}}>
                       
                      <FormControl fullWidth={true} sx={{mt: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="time">Timer Units</InputLabel>
                          {(error !== '' && error.time) ?
                          <Select
                          error
                          onChange={(e) => setPlanDetails(e.target.value)}
                          labelId="time"
                          id="time"
                          name="time"
                          label={`Timer Units`}
                          helperText={error.time}
                          >
                          <MenuItem selected value=''>
                          Select Option
                          </MenuItem> 
                          {tradingPlans.length > 0 && tradingPlans.map(plan =>(
                            <MenuItem selected value={plan.id}
                            >
                            {plan.duration}
                            </MenuItem>
                          ))}
                      </Select>
                        :
                        <Select
                            onChange={(e) => setPlanDetails(e.target.value)}
                            labelId="time"
                            name="time"
                            id="time"
                            label={`Timer Units`}
                            >
                            <MenuItem selected value=''>
                            Seconds
                            </MenuItem> 

                            {tradingPlans.length > 0 && tradingPlans.map(plan =>(
                            <MenuItem selected value={plan.id}>
                            {plan.duration}
                            </MenuItem>
                          ))}
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.time}
                        </Typography>
                    </FormControl>

                    <FormControl  fullWidth={true} sx={{marginLeft: '5px',  mt: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="duration">Duration</InputLabel>
                        {(error !== '' && error.duration) ?

                        <OutlinedInput
                        onChange={onChange}
                        error
                        id="duration"
                        type={'text'}
                        name="duration"
                        label="Duration"
                        helperText={error.duration}
                        />
                        :
                        <OutlinedInput
                        onChange={onChange}
                        id="duration"
                        type={'text'}
                        name="duration"
                        label="Duration"
                        />
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.duration}
                        </Typography>
                      </FormControl>
                    </Box>

                        {(selectedPlan !== '') &&
                        <Alert severity="success">Gain: {selectedPlan.min_range}% ~ {selectedPlan.max_range}%
                        </Alert>
                        }

                        <FormControl  fullWidth={true} sx={{ mt: 1, mb: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="amount">Stake (USD)</InputLabel>
                        <OutlinedInput
                          onChange={(e)=>setAmount(e.target.value)}
                          id="amount"
                          type={'number'}
                          label="Stake (USD)"
                        />
                      </FormControl>
                        {selectedPlan !== '' &&
                        <Alert severity="info">
                          <Typography>
                          Min Stake: {formatPrice(selectedPlan.min_amount)}
                          </Typography>
                          <Typography>
                          Max Stake: {formatPrice(selectedPlan.max_amount)} 
                          </Typography>                         
                        </Alert>
                        }

                    
                    

                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <FormControl  fullWidth={true} sx={{ mt: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="take_profit">Take Profit (optional)</InputLabel>
                      {(error !== '' && error.stake) ?

                      <OutlinedInput
                      onChange={onChange}
                      error
                      id="take_profit"
                      type={'text'}
                      name="take_profit"
                      label="Take Profit (optional)"
                      helperText={error.take_profit}
                      />
                      :
                      <OutlinedInput
                      onChange={onChange}
                      id="take_profit"
                      type={'text'}
                      name="take_profit"
                      label="Take Profit (optional)"
                      />
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.take_profit}
                      </Typography>
                  </FormControl>  

                  <FormControl fullWidth={true} sx={{marginLeft: '5px', mt: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="stop_loss">Stop Loss (optional)</InputLabel>
                      {(error !== '' && error.stake) ?

                      <OutlinedInput
                      onChange={onChange}
                      error
                      id="stop_loss"
                      type={'text'}
                      name="stop_loss"
                      label="Stop Loss (optional)"
                      helperText={error.stop_loss}
                      />
                      :
                      <OutlinedInput
                      onChange={onChange}
                      id="stop_loss"
                      type={'text'}
                      name="stop_loss"
                      label="Stop Loss (optional)"
                      />
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.stop_loss}
                      </Typography>
                  </FormControl> 
                  </Box> 

                      {(selectedPlan !== '' && amount !== 0) &&
                        <Alert severity="success">Potential Profit:  {formatPrice((selectedPlan.min_range/100) * amount)} ~ {formatPrice((selectedPlan.max_range/100) * amount)} 
                        </Alert>
                        }



{pathname=== '/start-trading-stock' &&
                        
                        <FormControl fullWidth={true} sx={{ mt: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="source">Select Payment Source</InputLabel>
                        {(error !== '' && error.duration) ?
                        <Select
                        error
                        onChange={onChange}
                        labelId="source"
                        id="source"
                        name="source"
                        label={`Select Payment Source`}
                        helperText={error.duration}
                        >
                        <MenuItem selected value=''>
                        Select Gateway
                        </MenuItem> 
  
                        <MenuItem selected value="Withdrawable Balance">
                          Spot Wallet (Bal = {formatPrice(mainBal)})
                          </MenuItem>
                          <MenuItem selected value="Referral Wallet">
                          Referral Wallet (Bal = {formatPrice(refBal)})
                          </MenuItem>  
  
                        {coinRates.length > 0 && 
                            coinRates.map((coin) =>(
                              <MenuItem key={coin.symbol} selected value={coin.symbol}>
                                {coin.symbol} (bal = {formatPrice(calculateBalance(coin.symbol))}) 
                              </MenuItem> 
                            ))}            
                        
                    </Select>
                        :
                        <Select
                            onChange={onChange}
                            labelId="source"
                            name="source"
                            id="source"
                            label={`Select Payment Source`}
                            >
                            <MenuItem selected value=''>
                            Select Gateway
                            </MenuItem> 
  
                            <MenuItem selected value="Withdrawable Balance">
                            Spot Wallet (Bal = {formatPrice(mainBal)})
                            </MenuItem>
                            <MenuItem selected value="Referral Wallet">
                            Referral Wallet (Bal = {formatPrice(refBal)})
                            </MenuItem> 
  
                            {coinRates.length > 0 && 
                            coinRates.map((coin) =>(
                              <MenuItem key={coin.symbol} selected value={coin.symbol}>
                                {coin.symbol} (bal = {formatPrice(calculateBalance(coin.symbol))}) 
                              </MenuItem> 
                            ))} 
  
                            {/* <MenuItem selected value="Funding Wallet">
                            Funding Wallet (Bal = {formatPrice(fundingBal)})
                            </MenuItem> */}
  
                            
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.source}
                        </Typography>
                    </FormControl>
                        }

                        </CardContent>

                        <CardActions sx={{justifyContent: 'space-between'}}>
                            <Button onClick={() => {
                              setOrderType('up');
                              setTimeout(() => {
                                postInvestment();
                              }, 1000);
                              
                            }} fullWidth={true} variant="contained" color='success' startIcon={<ArrowUpwardIcon />}>
                                 {isInvesting && orderType === 'up'? 'confirming...' : 'Up'}
                            </Button>

                            <Button onClick={() => {
                              setOrderType('down');
                              setTimeout(() => {
                                postInvestment();
                              }, 1000);
                            }} fullWidth={true} variant="contained" color='secondary' startIcon={<ArrowDownwardIcon />}>
                                {isInvesting && orderType === 'down' ? 'confirming...' : 'Down'}
                            </Button>

                        </CardActions>

                    </Card>
                </Grid>
            
            </Grid>
          }
        </Container>
    </div>
  )
}

export default TradeStock
