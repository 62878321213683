import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    plans: JSON.parse(localStorage.getItem('plans')) || [],
    tradingPlans: JSON.parse(localStorage.getItem('tradingPlans')) || [],

}

export const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setPlans: (state, action) =>{
            state.plans = action.payload;  
            localStorage.setItem('plans', JSON.stringify(state.plans));
        },
        setTradingPlans: (state, action) =>{
            state.tradingPlans = action.payload;  
            localStorage.setItem('tradingPlans', JSON.stringify(state.tradingPlans));
        }       
    }
})

export const {setPlans, setTradingPlans} = plansSlice.actions;
export default plansSlice.reducer;