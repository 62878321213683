import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthStatus, setToken, setPendingEmail } from '../../request/Auth/authSlice';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Currency } from '@depay/local-currency';
import { setUser } from '../Auth/userSlice';

import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const PersonalSettings = () => {
    const user = useSelector((state) => state.user.user);

    const [countries, setCountries] = useState('');
    const { isLoading: isloadingCountries, refetch: getCountries } = useQuery(
        "countries",
        async () => {
          return await apiClient.get("/api/countries");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setCountries(res.data.countries);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

    const dispatch = useDispatch();
    const initialLogin = {
        name: '',
        phone: '',
        country: ''

    }
   
    const [error, setErros] = useState('');
    
    const [registerForm, setRegisterForm] = useState(initialLogin);
    const appMode = useSelector((state) => state.mode.mode);
    const redirectTo = useSelector((state) => state.redirect.redirect);
    const inviter = useSelector((state) => state.referrals.inviter);

    const navigate = useNavigate();

    const onChange = (e) =>
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
    let currency = Currency.getCode();

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/personal-settings`, {
            name: registerForm.name,
            phone: registerForm.phone,
            country: registerForm.country,
          });
        },
        {
          onSuccess: (res) => {
            dispatch(setUser(res.data.user));
              localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
              const event2 = new Event('processed');
              window.dispatchEvent(event2);
              const event = new Event('newMessage');
              window.dispatchEvent(event);

          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );

      useEffect(() => {
        getCountries();
      }, []);

  return (
        <Card  sx={{borderRadius: '9px'}}>
            <CardContent>
            <Typography sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                PERSONAL SETTINGS
            </Typography>
            <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                Fill the form below to update personal settings
            </Typography>

            {(inviter !== '' && inviter !== null) &&
            <Box mx={2} mt={10}>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">Your referral code is {inviter}</Alert>
            </Stack>
            </Box>
            }

            <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                    <InputLabel htmlFor="name">Full Name</InputLabel>
                    {(error !== '' && error.name) ?
                    <OutlinedInput
                    error
                    onChange={onChange}
                    id="name"
                    defaultValue={user.name}
                    name="name"
                    type={'text'}
                    label="Full Name"
                    helperText={error.name}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="name"
                    defaultValue={user.name}
                    name="name"
                    type={'text'}
                    label="Full Name"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.name}
                    </Typography>
                    
                </FormControl>


                {(countries !== '' && countries.length > 0 )&&
                <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                <InputLabel htmlFor="country">Select Country</InputLabel>
                {(error !== '' && error.country) ?
                <Select
                error
                onChange={onChange}
                labelId="country"
                defaultValue={user.country}
                id="country"
                name="country"
                label={`Select Country`}
                helperText={error.country}
                >
                <MenuItem selected value=''>
                    Select Country
                </MenuItem> 
                {countries.map(country =>(
                    <MenuItem value={country.name}>{country.name} </MenuItem>
                ))}             
                
            </Select>
                :
                <Select
                    onChange={onChange}
                    labelId="country"
                    name="country"
                    id="country"
                    defaultValue={user.country}
                    label={`Select Country`}
                    >
                    <MenuItem selected value="">
                        Select Country
                    </MenuItem>              
                    {countries.map(country =>(
                    <MenuItem value={country.name}>{country.name} </MenuItem>
                    ))} 
                </Select>
                }
                <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                {error !== '' && error.country}
                </Typography>
            </FormControl>
                }
                


                <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                    <InputLabel htmlFor="phone">Phone</InputLabel>
                    {(error !== '' && error.phone) ? 
                    <OutlinedInput
                    error
                    onChange={onChange}
                    id="phone"
                    name="phone"
                    defaultValue={user.phone}
                    type={'tel'}
                    label="Phone"
                    helperText={error.phone}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="phone"
                    defaultValue={user.phone}
                    name="phone"
                    type={'tel'}
                    label="Phone"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.phone}
                    </Typography>
                </FormControl>


            </CardContent>                    
            <CardActions>
                <Button onClick={postLogin} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Saving...' : 'Save'}</Button>
            </CardActions>
        </Card>
  )
}

export default PersonalSettings
