import React, {useState} from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { useDispatch } from 'react-redux';
import { setUser } from '../Auth/userSlice';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import { useSelector } from 'react-redux';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyIcon from '@mui/icons-material/Key';
const OtherSettings = () => {
  const user = useSelector((state) => state.user.user);
  const [error, setErros] = useState('');
  const dispatch = useDispatch();
  const  [customSettings, setCustomSettings] = useState('');
  const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        
    async () => {
      return await apiClient.post(`/api/other-settings`, {
        custom_setting: customSettings,
      });
    },
    {
      onSuccess: (res) => {
        dispatch(setUser(res.data.user));
          localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
          const event2 = new Event('processed');
          window.dispatchEvent(event2);
          const event = new Event('newMessage');
          window.dispatchEvent(event);

      },
      onError: (err) => {   
        if (err.response?.data.length) {
          let myerror = err.response?.data || err;         
      setErros(myerror.errors)     
      const event2 = new Event('processed');
      window.dispatchEvent(event2);          
      }else{
          let errorMessage = err.response?.data.message || err ;
          localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
          const event = new Event('newMessage');
          window.dispatchEvent(event);
          const event2 = new Event('processed');
      window.dispatchEvent(event2);
      }    
      },
    }
  );

  const onSubmit = async() =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postLogin();
      } 

  return (
    <div>
      <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                <CardContent>
                    <List
                        sx={{ width: '100%', zIndex: 0, position: 'relative',  maxWidth: 360, bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>OTHER SETTINGS</ListSubheader>}
                        >
                        <ListItem>
                            <ListItemIcon>
                            <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText id="bots-notification" primary="Bots Trading Email Notification" />
                            <Switch
                            onChange={()=>
                              {setCustomSettings('bots_email');
                                setTimeout(() => {
                                  onSubmit();
                                }, 500);
                               
                              }

                            }
                            edge="end"
                            checked={user.bots_email === null ?
                              false:
                              user.bots_email
                            }
                            inputProps={{
                                'aria-labelledby': 'Bots Trading Email Notification',
                            }}
                            />
                        </ListItem>

                        
                        <ListItem>
                            <ListItemIcon>
                            <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText id="live-notification" primary="Live Trading Email Notification" />
                            <Switch
                            edge="end"
                            onChange={()=>
                              {setCustomSettings('live_email');
                                setTimeout(() => {
                                  onSubmit();
                                }, 500);
                               
                              }

                            }
                            checked={user.live_email === null ?
                              false:
                              user.live_email
                            }
                            inputProps={{
                                'aria-labelledby': 'Live Trading Email Notification',
                            }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                            <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText id="copy-notification" primary="Copy Trading Email Notification" />
                            <Switch
                            edge="end"
                            onChange={()=>
                              {setCustomSettings('copy_email');
                                setTimeout(() => {
                                  onSubmit();
                                }, 500);
                               
                              }

                            }
                            checked={user.copy_email === null ?
                              false:
                              user.copy_email
                            }
                            inputProps={{
                                'aria-labelledby': 'Copy Trading Email Notification',
                            }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                            <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText id="transfer-notification" primary="Transfer Email Notification" />
                            <Switch
                            edge="end"
                            onChange={()=>
                              {setCustomSettings('transfer_email');
                                setTimeout(() => {
                                  onSubmit();
                                }, 500);
                               
                              }

                            }
                            checked={user.transfer_email === null ?
                              false:
                              user.transfer_email
                            }
                            inputProps={{
                                'aria-labelledby': 'Transfer Email Notification',
                            }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                            <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText id="swap-notification" primary="Swap Email Notification" />
                            <Switch
                            edge="end"
                            onChange={()=>
                              {setCustomSettings('swap_email');
                                setTimeout(() => {
                                  onSubmit();
                                }, 500);
                               
                              }

                            }
                            checked={user.swap_email === null ?
                              false:
                              user.swap_email
                            }
                            inputProps={{
                                'aria-labelledby': 'Swap Email Notification',
                            }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                            <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText id="deposit-notification" primary="Deposits Email Notification" />
                            <Switch
                            edge="end"
                            onChange={()=>
                              {setCustomSettings('deposits_email');
                                setTimeout(() => {
                                  onSubmit();
                                }, 500);
                               
                              }

                            }
                            checked={user.deposits_email === null ?
                              false:
                              user.deposits_email
                            }
                            inputProps={{
                                'aria-labelledby': 'Deposit Email Notification',
                            }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                            <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText id="withdrawals-notification" primary="Withdrawals Email Notification" />
                            <Switch
                            edge="end"
                            onChange={()=>
                              {setCustomSettings('withdrawals_email');
                                setTimeout(() => {
                                  onSubmit();
                                }, 500);
                               
                              }

                            }
                            checked={user.withdrawals_email === null ?
                              false:
                              user.withdrawals_email
                            }
                            inputProps={{
                                'aria-labelledby': 'Withdrawals Email Notification',
                            }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                            <KeyIcon/>
                            </ListItemIcon>
                            <ListItemText id="otp-logins" primary={'Enable Login OTP'} />
                            <Switch
                            edge="end"
                            onChange={()=>
                              {setCustomSettings('otp_status');
                                setTimeout(() => {
                                  onSubmit();
                                }, 500);
                               
                              }

                            }
                            checked={user.otp_status === null ?
                              false:
                              user.otp_status
                            }
                            inputProps={{
                                'aria-labelledby': 'Enable Login OTP',
                            }}
                            />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
      </div>
  )
}

export default OtherSettings
