import React, {useEffect, useState} from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import apiClient from '../../request/http-common';
import { Fragment } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {MiniChart} from "react-ts-tradingview-widgets";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import WalletButton from '../Wallet/WalletButton';
import { useSelector } from 'react-redux';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import EarningAnalysis from '../Earning/EarningAnalysis';
import Cards from '../Cards/Cards';
import { useDispatch } from 'react-redux';
import { setcurrentPair } from '../Trader/tradingSlice';
import { useNavigate } from 'react-router-dom';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';


const ForexTrader = () => {

    const forex = useSelector((state) => state.coins.forex);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appMode = useSelector((state) => state.mode.mode);

  return (
      <div style={{marginTop: '75px'}}>
        <Typography mb={1}  ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textTransform: 'uppercase', textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                TRADE FOREX MARKET
        </Typography>
        <Typography  sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
                kindly click any asset pair to trade
        </Typography>

        {forex.length < 1 &&
        <CircularProgress/>
        }
        {forex.length > 0 && 

        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

            {forex.map((coin) =>(
                <Fragment key={coin.symbol}>
                <ListItem alignItems="flex-start" sx={{cursor: 'pointer'}}
                onClick={()=>{
                    dispatch(setcurrentPair(coin.symbol));                  
                  setTimeout(() => {
                    navigate('/start-trading-forex');
                  }, 100);
              }}
                >
          <ListItemAvatar>
          <CandlestickChartIcon/>
        </ListItemAvatar>
                <ListItemText
                  primary={coin.symbol}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {coin.name}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'flex-start'}}>
                <Button
                onClick={()=>{
                    if (coin.symbol === 'USDT') {
                      dispatch(setcurrentPair('BTC'));
                    }else{
                      dispatch(setcurrentPair(coin.symbol));
                    }                    
                    setTimeout(() => {
                      navigate('/start-trading-forex');
                    }, 100);
                }}

                fullWidth={false} edge="end" size="small" color="primary" variant="contained"> 
                trade
                </Button> 
                </Box>
              </ListItem>
              <Divider variant="inset" component="li" />
              
              </Fragment>
            ))}  
      </List>
        
        }
      
    </div>
  )
}

export default ForexTrader
